import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Image, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Dienstleistungen | Sparkling Clean
			</title>
			<meta name={"description"} content={"Verbessern Sie Ihr Reinigungserlebnis"} />
			<meta property={"og:title"} content={"Dienstleistungen | Sparkling Clean"} />
			<meta property={"og:description"} content={"Verbessern Sie Ihr Reinigungserlebnis"} />
			<meta property={"og:image"} content={"https://novatid.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://novatid.com/img/1999164.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://novatid.com/img/1999164.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://novatid.com/img/1999164.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://novatid.com/img/1999164.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://novatid.com/img/1999164.png"} />
			<meta name={"msapplication-TileImage"} content={"https://novatid.com/img/1999164.png"} />
			<meta name={"msapplication-TileColor"} content={"https://novatid.com/img/1999164.png"} />
		</Helmet>
		<Components.ToluqyeHeader />
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Dienstleistungen
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 280px 0px 280px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				Sparkling Clean
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="0px 0px 30px 0px"
				grid-template-columns="repeat(2, 1fr)"
				md-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://novatid.com/img/5.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Erleben Sie unsere Dienstleistungen
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Bei Sparkling Clean Services sind wir stolz darauf, außergewöhnliche Reinigungslösungen zu liefern, die auf die individuellen Bedürfnisse unserer Kunden zugeschnitten sind. Unser umfassendes Leistungsangebot sorgt dafür, dass jede Ecke Ihres Zuhauses vor Sauberkeit und Frische glänzt. Egal, ob Sie eine einmalige Tiefenreinigung oder regelmäßige Wartung wünschen, unser professionelles Team ist bestrebt, Ihre Erwartungen zu übertreffen.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					margin="0px 30px 0px 30px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://novatid.com/img/6.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Allgemeine Reinigung
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Regelmäßige Instandhaltung: Staubwischen, Staubsaugen und Wischen, um ein sauberes und ordentliches Zuhause zu erhalten.
						<br/><br/>
Oberflächenreinigung: Gründliche Reinigung von Arbeitsplatten, Tischen und anderen Oberflächen.
						<br/><br/>
Müllentsorgung: Effiziente Entfernung und Entsorgung von Haushaltsmüll.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://novatid.com/img/7.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Tiefenreinigung
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Detaillierte Aufmerksamkeit: Reinigung hinter und unter Möbeln, Geräten und an schwer erreichbaren Stellen.
						<br/><br/>
Schrubben von Fugen und Fliesen: Intensives Schrubben, um Schmutz und Ruß von Fliesen und Fugen zu entfernen.
						<br/><br/>
Fensterreinigung: Makellos saubere Reinigung von Fenstern und Fensterbänken für eine klare Sicht.

						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://novatid.com/img/8.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Reinigung bei Einzug/Auszug
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Reinigung leerstehender Immobilien: Umfassende Reinigung leerstehender Häuser zur Vorbereitung auf neue Bewohner.
						<br/><br/>
Reinigung bei Mietwechsel: Sicherstellen, dass Mietobjekte zwischen den Mietern makellos sind.
						<br/><br/>
Letzter Schliff: Polieren und Detailarbeiten für ein makelloses Finish.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://novatid.com/img/9.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Spezialreinigung
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Küchenreinigung: Tiefenreinigung von Öfen, Kühlschränken und Schränken.
						<br/><br/>
Badezimmerreinigung: Desinfizieren und Schrubben von Duschen, Badewannen, Waschbecken und Toiletten.
						<br/><br/>
Teppich- und Polsterreinigung: Professionelle Reinigung zur Entfernung von Flecken und Gerüchen.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://novatid.com/img/10.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Unser professionelles Team
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Unser hochqualifiziertes und erfahrenes Team ist der Kern unseres Erfolgs. 
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Überlassen Sie die Reinigung Sparkling Clean Services, damit Sie sich ganz auf Ihr Zuhause konzentrieren können. Mit unserem professionellen Team, umweltfreundlichen Produkten und unserem Engagement für Spitzenleistungen garantieren wir, dass Sie mit den Ergebnissen zufrieden sein werden. Kontaktieren Sie uns noch heute, um den ultimativen Reinigungsservice zu erleben. Denken Sie daran, mit Sparkling Clean Services ist ein saubereres Zuhause nur einen Anruf entfernt.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				background="#ffdd61"
				color="--dark"
				type="link"
				href="/contact-us"
				text-decoration-line="initial"
			>
				Kontakte
			</Button>
		</Section>
		<Components.ToluqyeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});